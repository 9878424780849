







import { Component } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import FullCalendarComponent from '@/components/full-calendar/FullCalendarComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: { FullCalendarComponent },
})
export default class AgendaWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  created(): void {
    this.setDataConfig();
  }
}
